import React from 'react';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import {
  Login,
  Logout,
  LoginReducer,
  MenuReducer,
  MessageReducer,
  NoticeReducer,
  ConfirmReducer,
  ContractSelectReducer,
  ConstructionSiteSelectReducer,
  TimeRangeReducer
} from 'components';
import RoadHandReducer from '../roadHand/Reducer';

import App from './Application.js';
import RoadHand from '../roadHand/RoadHand.js';

export const store = createStore(
  combineReducers({
    login: LoginReducer,
    menu: MenuReducer,
    message: MessageReducer,
    notice: NoticeReducer,
    confirm: ConfirmReducer,
    roadHand: RoadHandReducer,
    constructionSiteSelect: ConstructionSiteSelectReducer,
    contractSelect: ContractSelectReducer,
    timeRange: TimeRangeReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const AppWithLocation = () => {
  const location = useLocation();

  return <App store={store} location={location} />;
};

const Root = () => (
  <Provider store={store}>
    <span id="timer" />
    <BrowserRouter>
      <AppWithLocation store={store} />
      <Routes>
        <Route path='/login' element={<Login store={store} />}/>
        <Route path='/logout' element={<Logout store={store} />}/>
        <Route path='/' element={<RoadHand store={store} />}/>
      </Routes>
    </BrowserRouter>
  </Provider>
);

export default Root;
import React from 'react';
import { connect } from 'react-redux';
import { ContractSelect, ConstructionSiteSelect,
         showNotice, setContracts, selectContructionSite,
         selectContract } from 'components';
import Reports from './Reports';
import ConstructionSites from './ConstructionSites';
import Contracts from './Contracts';
import { clearReports, addReports, removeReport, addReport, changeReport } from './Actions';
import { fetch, Socket } from '../utils';
import './RoadHand.css';


class RoadHand extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: 0,
      years: [],
      loading: false,
      selectedYear: null,
      orderer: false
    };

    this.changeState = this.changeState.bind(this);
    this.toggleSelectingSite = this.toggleSelectingSite.bind(this);
    this.setYear = this.setYear.bind(this);
  }

  componentDidMount() {
    if (localStorage['login'] == null) {
      return;
    }
    if (this.socket == null && typeof(WebSocket) !== 'undefined') {
      this.socket = Socket('/data/roadhand');
      this.socket.onmessage = function(e) {
        const data = JSON.parse(e.data);
        if (data['operation'] === 'create') {
          if (data.model.construction_site) {
            if (this.props.selectedConstructionSite.get('id') !== data.model.constructionSiteId) {
              return;
            }
          }
          else {
            const exist = this.props.reports.find(mass => mass.id === data.model.id);

            if (!exist) {
              return;
            }
          }

          this.getReports(this.props.selectedConstructionSite.get('id'));
        }
        else if (data['operation'] === 'update') {
          if (data.model.construction_site) {
            if (this.props.selectedConstructionSite.get('id') !== data.model.construction_site.id) {
              return;
            }
            this.props.changeReport(data.model);
          }
        }
        else if (data['operation'] === 'delete') {
          this.props.removeReport(data.model);
        }
      }.bind(this)
    }

    if (!this.props.selectedConstructionSite) return;

    this.getReports(this.props.selectedConstructionSite.get('id'));
  }

  componentDidUpdate(lastProps, lastState) {
    if (this.props.user != null && lastProps.user !== this.props.user) {
      const orderer = this.props.user.get('orderer') || 
                      this.props.user.get('organizationId') !== 1;
      this.setState({ orderer: orderer });
    }

    if (this.props.contracts !== lastProps.contracts) {
      this.getYears();
    }
    if (this.props.selectedContract !== lastProps.selectedContract) {
      this.props.selectContructionSite(null);
    }
    if (this.state.selectedYear !== lastState.selectedYear) {
      this.props.selectContructionSite(null);
      this.props.selectContract(null);
    }
  }

  componentWillUnmount() {
    if (this.socket != null) this.socket.close();
  }

  changeState(state) {
    this.setState({ state: state });
  }

  async toggleSelectingSite() {
    await this.setState({ selectingSite: !this.state.selectingSite });
  }

  async getContracts() {
    let contracts = [];

    try {
      contracts = await fetch('/contracts');
    } catch {}

    return contracts;
  }

  async getYears() {
    const contracts = await this.getContracts();

    let years = [];

    for (let contract of contracts) {
      const year = contract.year;

      if (year == null || years.includes(year)) continue;

      years.push(contract.year)
    }

    this.setState({ years: years });
  }

  setYear(event) {
    event.persist();
    
    this.getContracts().then(contracts => {
      let year = null;

      if (event.target.value !== '') {
  
        year = parseInt(event.target.value, 10);
  
        this.getContracts(year);
      }

      if (year !== '' &&
          this.props.selectedConstructionSite != null &&
          year !== this.props.selectedConstructionSite.get('contract').get('year')) {
      }
  
      this.setState({ selectedYear: year });
    });
  }

  render() {
    if (this.props.organizationId == null) {
      return null;
    }

    return (
      <div>
        <div className="center">
          <h1>Tierenki</h1>
        </div>
        <div id='roadhand-container'>
          <fieldset>
            <legend>
              <div>
                <div className={"state" + (this.state.state === 0 ? ' selected' : '')}
                onClick={this.state.state === 0 ? null : this.changeState.bind(null, 0)}>
                  Raportit
                </div>
                <div className={"state" + (this.state.state === 1 ? ' selected' : '')}
                onClick={this.state.state === 1 ? null : this.changeState.bind(null, 1)}>
                  Kohteet
                </div>
                { !this.state.orderer ?
                  <div className={"state" + (this.state.state === 2 ? ' selected' : '')}
                        onClick={this.state.state === 2 ? null : this.changeState.bind(null, 2)}>
                    Urakat
                  </div>
                  :
                  null
                }
              </div>
            </legend>
            <div id='roadhand' className="row">
              <div className="column">
                <label>
                  Kausi
                  <select onChange={this.setYear}>
                    <option value={''}>
                      Kaikki
                    </option>
                    {
                      this.state.years.map(year => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))
                    }
                  </select>
                </label>
              </div>
              { this.state.state <= 1 ?
                <div className="column">
                  <ContractSelect year={this.state.selectedYear} store={this.props.store} />
                </div>
                :
                null
              }
              { this.state.state === 0 ?
                <div className="column">
                  <ConstructionSiteSelect store={this.props.store} />
                </div>
                :
                null
              }
            </div>
            <Reports show={this.state.state === 0}
                     selectedYear={this.state.selectedYear}
                     orderer={this.state.orderer} />
            <ConstructionSites show={this.state.state === 1}
                               orderer={this.state.orderer} />
            <Contracts show={this.state.state === 2}
                       year={this.state.selectedYear} />
          </fieldset>
          { this.state.loading ? <div className="main loader"/> : null }
        </div>
      </div>
    );
  }
}


export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  contracts: state.contractSelect.get('contracts'),
  constructionSites: state.constructionSiteSelect.get('constructionSites'),
  reports: state.roadHand.get('reports'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
  user: state.login.get('user'),
}), { clearReports, addReports, removeReport, addReport, changeReport,
      showNotice, setContracts, selectContructionSite,
      selectContract })(RoadHand);

import React from 'react';
import { connect } from 'react-redux';
import { showConfirm, showNotice, addContract, removeContract, setContracts,
         changeContract, showMessage } from 'components';
import { fetch, stateValueParser, timer } from '../utils';
import './RoadHand.css';


const Fields = props => (
  <div className="row">
    <div className="column">
      <label htmlFor='name'>
        Nimi
      </label>
      <input id='name' type='text'
            onChange={props.onChange.bind(this, 'name', 'string', '')}
            value={props.name || ''} />
    </div>
    <div className="column">
      <label htmlFor='year'>
        Kausi
      </label>
      <input id='year' type='number'
            onChange={props.onChange.bind(this, 'year', 'integer', '')}
            value={props.year || ''} />
    </div>
  </div>
);

const NewContract = props => {
  if (!props.show) {
    return null;
  }

  return (
    <div onClick={props.toggle} className='modal'>
      <div id='modal-container' onClick={e => e.stopPropagation()}>
        { !props.loading ?
          <div>
            <h3>Uusi urakka</h3>
            <Fields onChange={props.onChange}
                    name={props.name}
                    year={props.year} />
            <div className="row inputs">
              <div className="column">
                <button onClick={props.createNewContract}>
                  Lisää
                </button>
              </div>
              <div className="column">
                <button className='button-outline' onClick={props.toggle}>
                  Peruuta
                </button>
              </div>
            </div>
          </div>
          :
          <div className='loader' />
        }
      </div>
    </div>
  )
}

const EditContract = props => {
  if (!props.show) {
    return null;
  }

  return (
    <div onClick={props.toggle} className='modal'>
      <div id='modal-container' onClick={e => e.stopPropagation()}>
        { !props.loading ?
          <div>
            <h3>Muokkaa urakkaa</h3>
            <h3>
              {props.editingContract.get('name')}
            </h3>
            <Fields onChange={props.onChange}
                    name={props.name}
                    year={props.year} />
            <div className="row inputs">
              <div className="column">
                <button onClick={props.editContract}>
                  Lisää
                </button>
              </div>
              <div className="column">
                <button className='button-outline' onClick={props.toggle}>
                  Peruuta
                </button>
              </div>
            </div>
          </div>
          :
          <div className='loader' />
        }
      </div>
    </div>
  )
}

class Contracts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      creatingNewContract: false,
      name: '',
      year: null,
      deletingContractId: null,
      loading: false,
    };

    this.changeState = this.changeState.bind(this);
    this.toggleNewContract = this.toggleNewContract.bind(this);
    this.clearNewContract = this.clearNewContract.bind(this);
    this.createNewContract = this.createNewContract.bind(this);
    this.deleteContract = this.deleteContract.bind(this);
    this.clearEditContract = this.clearEditContract.bind(this);
    this.editContract = this.editContract.bind(this);
  }

  componentDidUpdate(lastProps, lastState) {
    if (this.props.year !== lastProps.year) {
      this.getContracts();
    }
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }

    this.setState({ [propertyName]: value });
  }

  async getContracts() {
    let contracts = [];

    this.setState({ loading: true });

    try {
      contracts = await fetch('/contracts');

      if (this.props.year != null) {
        contracts = contracts.filter(contract => contract.year === this.props.year);
      }
    } catch {}

    this.setState({ loading: false });
    this.props.setContracts(contracts);
  }

  toggleNewContract() {
    this.setState({ creatingNewContract: !this.state.creatingNewContract });
  }

  clearNewContract() {
    this.toggleNewContract();

    this.setState({
      name: '',
      year: null,
    });
  }

  async createNewContract() {
    if (this.state.name === '') {
      this.props.showNotice('Nimeä ei ole annettu', 'Warning');
      return;
    }

    this.setState({ loadingInput: true });

    const contract = {
      name: this.state.name,
      year: this.state.year
    };

    try {
      const data = await fetch('/contracts', 'POST', contract);

      if (this.props.year == null || (this.props.year != null && this.props.year === data.year)) {
        this.props.addContract(data);
      }

      this.props.showNotice('Lisätty', 'Ok');
      this.clearNewContract();
    } catch(error) {
      this.props.showNotice('Lisääminen epäonnistui', 'Error');
    }

    this.setState({ loadingInput: false });
  }

  aboutToDeleteContract(contract) {
    this.setState({ deletingContractId: contract.get('id') });

    this.props.showConfirm('Poistetaanko raportti: ' + contract.get('name') + ' ' +
    (contract.get('year') || '-') + '?', this.deleteContract);
  }

  async deleteContract() {
    timer(0).then(async () => {
      this.setState({ loading: true });
      try {
         const data = await fetch('/contracts/' + this.state.deletingContractId, 'DELETE');
         
         if (data !== undefined) {
          this.props.removeContract(this.state.deletingContractId);
          this.props.showNotice('Poistettu', 'Ok');
         }
         else {
          this.props.showNotice('Poisto epäonnistui, koska urakalla on kohteita', 'Error');
         }
         
      } catch(error) {
        if (error.message === '409') {
          this.props.showMessage('Virhe', 'Urakkaan kuuluu kohde tai useampi', 'Error');
        }
        else {
          this.props.showNotice('Poisto epäonnistui', 'Error');
        }
      }
      this.setState({ loading: false });
    });
  }

  async showContract(contract) {
    if (this.props.orderer) {
      return;
    }

    this.setState({
      editingContract: contract,
      name: contract.get('name'),
      year: contract.get('year')
    });
  }

  clearFields() {
    this.setState({
      name: '',
      year: null,
    });
  }

  clearEditContract() {
    this.setState({ editingContract: null });
    this.clearFields();
  }

  async editContract() {
    if (this.state.name === '') {
      this.props.showNotice('Nimeä ei ole annettu', 'Warning');
      return;
    }

    this.setState({ loadingInput: true });

    const contract = {
      name: this.state.name,
      year: this.state.year
    };

    try {
      const data = await fetch('contracts/' + this.state.editingContract.get('id'),
                     'PATCH', contract);
      this.props.changeContract(data);
      this.props.showNotice('Muokattu', 'Ok');
      this.clearEditContract();
    } catch(error) {
      this.props.showNotice('Muokkaus epäonnistui', 'Error');
    }

    this.setState({ loadingInput: false });
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    if (this.state.loading) {
      return <div className='loader' />
    }

    return (
      <div>
        <button onClick={this.toggleNewContract}>
          Uusi urakka
        </button>
        { this.props.contracts.size !== 0 ?
          <div className="row">
            <div className="column">
              <b>Nimi</b>
            </div>
            <div className="column">
              <b>Kausi</b>
            </div>
            <div className="column">
            </div>
          </div>
          :
          <h3>Ei yhtään urakkaa</h3>
        }
        {
          this.props.contracts.map(contract => (
            <div key={contract.get('id')} className="report"
                 onClick={this.showContract.bind(this, contract)}>
              <div className="row">
                <div className="column">
                  {contract.get('name')}
                </div>
                <div className="column">
                  {contract.get('year') || '-'}
                </div>
                <div className="column">
                  <button className='button-outline' onClick={(e) => {
                    e.stopPropagation();
                    this.aboutToDeleteContract(contract);
                  }}>
                    Poista
                  </button>
                </div>
              </div>
            </div>
          ))
        }
        <NewContract show={this.state.creatingNewContract}
                     onChange={this.changeState}
                     name={this.state.name}
                     year={this.state.year}
                     createNewContract={this.createNewContract}
                     toggle={this.toggleNewContract}
                     loading={this.state.loading} />
        <EditContract show={this.state.editingContract != null}
                     onChange={this.changeState}
                     name={this.state.name}
                     year={this.state.year}
                     editContract={this.editContract}
                     toggle={this.clearEditContract}
                     loading={this.state.loading} 
                     editingContract={this.state.editingContract} />
      </div>
    );
  }
}

export default connect(state => ({
  contracts: state.contractSelect.get('contracts'),
}), { showNotice, showConfirm, addContract, removeContract,
      setContracts, changeContract, showMessage })(Contracts);
